<template>
  <div class="status">
    <div class="container">
      <div class="contnet-box" v-if="isLoading == false && purchase != ''">
        <img src="../../src/assets/payment-success.svg" alt="payment-success" />

        <h3>
          Successfully Subscribed <b>{{ purchase.title }}</b>
        </h3>
        <div v-if="purchase.amount > 0">
          <p>Transaction Id: {{ purchase.transaction_id }}</p>
          <p>Amount: {{ purchase.amount }}</p>
        </div>
        <router-link class="btn cta-outline" :to="{ name: 'dashboardTab' }"
          >Go to Dashboard</router-link
        >
      </div>
      <div class="contnet-box" v-else-if="isLoading == false && purchase == ''">
        <img src="../../src/assets/payment-failed.svg" alt="payment-failed" />
        <h3>
          Subscription failed
        </h3>
        <p>If any amount deducted in your account please contact us</p>
      </div>
      <div class="contnet-box" v-else>
        <circular-loader></circular-loader>
        <h3>
          Please wait..
        </h3>
      </div>
    </div>
  </div>
</template>

<script>
import MembershipService from "@/services/MembershipService";
import NestMembershipService from "@/services/NestMembershipService";
import errorLog from "@/errorLog";
import CircularLoader from "@/components/loaders/CircularLoader";

export default {
  name: "PaymentSuccess",
  components: {
    CircularLoader
  },
  data() {
    return {
      isLoading: true,
      purchase: [],
      membership_type: this.$route.query.source
    };
  },
  created() {
    this.getPurchaseDetail();
    //console.log(this.$route.query.source);
  },
  methods: {
    getNestPurchaseDetails() {
      const data = {
        id: this.$route.params.id,
        membership_type: this.$route.query.source
      };
      NestMembershipService.getPurchaseDetails(data)
        .then(result => {
          this.purchase = result.data.purchase;
          this.isLoading = false;
        })
        .catch(error => {
          errorLog.log(error);
        });
    },
    getPurchaseDetail() {
      const data = {
        id: this.$route.params.id,
        membership_type: this.$route.query.source
      };
      if (this.membership_type == "nest") {
        this.getNestPurchaseDetails();
      } else {
        MembershipService.getPurchaseDetail(data)
          .then(result => {
            this.isLoading = false;
            if (result.data.status == "success") {
              this.purchase = result.data.purchase;
              console.log(this.purchase);
            }
          })
          .catch(error => {
            this.isLoading = false;
            errorLog.log(error);
          });
      }
    }
  }
};
</script>

<style lang="scss">
.status {
  padding-top: 100px;
  min-height: 80vh;
  .contnet-box {
    max-width: 800px;
    margin: auto;
    text-align: center;
    padding: 4rem 0;
    img {
      padding-bottom: 40px;
      width: 90px;
    }
    h3 {
      color: #535353;
    }
  }
}
</style>
